import '../sass/text.scss';

(() => {
    const $textBlocks = document.querySelectorAll('.text');

    if (!$textBlocks) return;

    $textBlocks.forEach($textBlock => {
        const $gradient = $textBlock.querySelector('.text__gradient');

        const initParallax = () => {
            if (!$gradient) return;

            if (window.innerWidth < 768) {
                $gradient.style.marginTop = '';
                $gradient.style.marginBottom = '';
                $gradient.style.transform = '';

                return;
            }

            const movement = window.innerWidth;
            
            let scrollFraction = (-1 * ($textBlock.getBoundingClientRect().top - window.innerHeight)) / ($textBlock.clientHeight + window.innerHeight);

            if (scrollFraction > 1) scrollFraction = 1;
            if (scrollFraction < 0) scrollFraction = 0;

            $gradient.style.marginTop = `-${movement}px`;
            $gradient.style.marginBottom = `-${movement}px`;
            $gradient.style.transform = `translateY(${- movement +(movement * 2) * scrollFraction}px)`;
        };

        window.addEventListener('scroll', initParallax);
        window.addEventListener('resize', initParallax);

        initParallax();
    });
})();
